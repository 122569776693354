import { signOut } from '@aws-amplify/auth';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { QueryClientContext } from '@tanstack/react-query';
import { usePostHog } from 'posthog-js/react';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { AppRoute } from '~/lib';
import { SessionContext } from '~/navigation';

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui';

export const Actions = (): React.ReactElement => {
  const posthog = usePostHog();
  const { admin } = useContext(SessionContext);
  const queryClient = useContext(QueryClientContext);
  const navigate = useNavigate();

  const signout = async () => {
    await signOut({ global: true });
    await queryClient?.invalidateQueries({ queryKey: ['authSession'] });
    posthog.reset();
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary">
          <DotsHorizontalIcon className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {admin && (
          <>
            <DropdownMenuItem
              onSelect={() => navigate(AppRoute.Lessons)}
              className="hover:cursor-pointer"
            >
              <FormattedMessage id="common.lessons" />
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => navigate(AppRoute.Problems)}
              className="hover:cursor-pointer"
            >
              <FormattedMessage id="common.problems" />
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => navigate(AppRoute.Users)}
              className="hover:cursor-pointer"
            >
              <FormattedMessage id="common.createUser" />
            </DropdownMenuItem>
            <DropdownMenuItem
              onSelect={() => navigate(AppRoute.Products)}
              className="hover:cursor-pointer"
            >
              <FormattedMessage id="common.products" />
            </DropdownMenuItem>
          </>
        )}
        <DropdownMenuItem
          onSelect={signout}
          className="text-red-600 hover:cursor-pointer"
        >
          <FormattedMessage id="common.logout" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
