/* eslint-disable max-lines */
import { ChapterAsObject, NotionAsObject } from '@skloover/shared';

const chapter: {
  [key in FrenchChapter]: string;
} = {
  real_numbers: 'Nombres réels et inégalités',
  integers: 'Nombres entiers, arithmétique',
  calculation: 'Calcul littéral et application',
  geometry: 'Géométrie plane',
  vectors: 'Vecteurs du plan',
  coordinate_system: 'Géométrie plane dans un repère',
  plan_line_and_system: 'Droites du plan et systèmes',
  // numerical_functions: 'Fonctions numériques',
  // equations_inequations: 'Fonctions, équations et inéquations',
  // variations_extremums: 'Variations et extremums',
  statistics: 'Statistiques',
  probability: 'Probabilités',
  // sampling: 'Échantillonage',
  // algorithmic: 'Algorithme et programmation',
  // functions: 'Fonctions linéaires',
  // exponential: 'Fonctions exponentielles',
  // derived_number: 'Nombre dérivé',
  // derived_function: 'Fonctions dérivées',
};

const notion: {
  [key in FrenchNotion]: string;
} = {
  universe_output_event_definition: 'Vocabulaire',
  complementary_probability_definition: 'Complémentarité - définition',
  joint_probability_definition: 'Intersection et réunion',
  probability_law: 'Loi de probabilité',
  equiprobability: 'Équiprobabilité',
  incompatibility_calculation: 'Incompatibilité',
  joint_probability_calculation: 'Calcul de probabilité',
  complementary_probability_calculation: 'Complémentarité - calcul',
  // vocabulary: 'Vocabulaire',
  // definition: 'Définitions',
  // recurrence_relation: 'Relation de récurrence',
  // explicit_form: 'Forme explicite',
  // variations: 'Variations',
  // conditional_probability: 'Probabilités conditionnelles',
  // complementary_probability: 'Complémentarité',
  // false_positive: 'Faux positifs',
  // event_dependency: 'Indépendance',
  // ponderated_tree: 'Arbre pondéré',
  // independant_succession: "Succession d'épreuves indépendantes",
  // incompatibility_definition: 'Incompatibilité - définition',
  // growth_rate: 'Taux de variation',
  // linear_growth: 'Variation linéaire',
  frequency_definition: 'Proportion - définition',
  frequency_calculation: 'Proportion - calcul',
  frequency_frequency: 'Proportion de proportion',
  variations_definition: 'Variation absolue, variation relative',
  growth_rate_product: "Taux d'évolution et coefficient multiplicateur",
  successive_evolution: 'Évolutions succéssives',
  reciprocal_evolution: 'Évolutions réciproques',
  average_definition: 'Moyenne - définition',
  average_operations: 'Opérations sur la moyenne',
  standard_deviation: 'Écart type',
  median: 'Médiane et écart interquartile',
  // conditional_probability_definition: 'Probabilités conditionnelles',
  // weighted_tree: 'Arbres pondérés',
  // universe_partition_definition: 'Partition',
  // complementary_partition: 'Complémentarité',
  // total_probability: 'Probabilités totales',
  // independent_events: 'Indépendance',
  // independent_complementary_events: 'Indépendance et complémentarité',
  // independent_events_succession: 'Épreuves indépendantes',
  line_of_reals: 'Droite des réels',
  intervals: 'Intervalles',
  absolute_value: 'Valeur absolue',
  decimals: 'Décimaux',
  approximate_value: 'Valeur approchée',
  rationals: 'Rationnels',
  integer_power: 'Puissance entière',
  square_root: 'Racine carrée',
  reals_addition: "Somme d'inégalités",
  reals_multiplication: "Produit d'inégalités",
  intervals_composition: 'Union et intersection',
  whole_and_relative: 'Entiers naturels et relatifs',
  multiple_and_divider: 'Multiples et diviseurs',
  multiple_sum_and_product: 'Opérations sur les multiples',
  prime: 'Nombres premiers',
  odd_even_definition: 'Parité - définition',
  parity_sum: 'Parité - somme',
  parity_square: 'Parité - carré',
  algebric_expressions: 'Expression algébrique',
  distributivity: 'Formules de distributivité',
  remarkable_identities: 'Identités remarquables',
  factorisation: 'Techniques de factorisation',
  quotient_product: 'Quotient - calcul algébrique',
  quotient_operations: 'Quotient - opérations',
  quotient_inverse: 'Quotient - inverse',
  comparison_sign: 'Comparaison de deux nombres',
  comparison_criteria: 'Critères de comparaison',
  equations: "Résolution d'équations",
  first_degree: 'Équations du premier degré',
  pythagore: 'Théorème de Pythagore',
  thales: 'Théorème de Thales',
  angles: 'Triangle - angles',
  trigonometry_formula: 'Formule trigonométrique',
  triangle_angles_sum: 'Triangle - somme des angles',
  internal_alternate_angle: 'Angles alterne - interne',
  quadrilateral: 'Quadrilatères',
  circles: 'Cercles',
  inscribed_angle: 'Angle inscrit',
  tangent: 'Tangente',
  mediator: 'Médiatrices',
  triangle_lines: 'Triangle - droites remarquables',
  orthogonal_projection: 'Projeté orthogonal',
  translations: 'Translations',
  equality: 'Égalité',
  chasles: 'Relation de Chasles',
  opposite: 'Opposé',
  real_product: 'Produit par un réel',
  colinearity: 'Colinéarité',
  orthonormal: 'Repère orthonormé',
  coordinates: 'Coordonnées',
  colinearity_criteria: 'Critère de colinéarité',
  middle: "Milieu d'un segment",
  vector_norm: 'Norme et distance',
  determinant: 'Déterminant',
  director_vector: 'Vecteur directeur',
  cartezian_equation: 'Équation cartésienne',
  y_parallelism: 'Parallèle à l’axe des ordonnées',
  y_no_parallelism: 'Droites non parallèles à l’axe des ordonnées',
  linear_system: 'Système linéaire',
  graphical_interpretation: 'Interprétation graphique',
  system_resolution: 'Méthodes de résolution d’un système',
  // vocabulary: 'Vocabulaire',
  // graphical_representation: 'Représentation graphique',
  // parity: 'Parité',
  // square_function: 'Fonction carré',
  // inverse_function: 'Fonction inverse',
  // cubic_function: 'Fonction cube',
  // square_root_function: 'Fonction racine carrée',
  // inequality: 'Inégalités et fonctions de référence',
  // relative_position: 'Positions relatives des courbes',
  // equation: 'Résolution d’une équation',
  // inequation: 'Résolution d’une inéquation',
  // sign_table: 'Tableau de signes',
  // linear_sign: 'Signe d’une expression affine',
  // inequation_equivalence: 'Inéquations équivalentes',
  // product: 'Signe d’un produit et inéquation-produit',
  // quotient: 'Signe d’un quotient et inéquation-quotient',
  // growth: 'Croissance d’une fonction',
  // variation_table: 'Tableau de variations',
  // min_max: 'Extremums d’une fonction',
  // growth_rate: 'Taux d’accroissement',
  // variation: 'Sens de variation',
  // large_numbers: 'Loi des grands nombres',
  // probability_estimate: 'Estimation d’une probabilité',
  // proprtion_estimate: 'Estimation d’une proportion',
  // accuracy: 'Précision de l’estimation',
  // conditional_structures: 'Structure conditionnelle',
  // functions: 'Fonctions',
  // python_implementation: 'Construire une fonction en Python',
  // for_loop: 'Boucle bornée',
  // while_loop: 'Boucle non bornée',
};

const division: {
  [key in FrenchDivision]: string;
} = {
  // '11': 'Première',
  '10': 'Seconde',
};

type FrenchNotion = Pick<
  Extract<NotionAsObject, { country: 'france' }>,
  'notion'
>['notion'];

type FrenchChapter = Pick<
  Extract<ChapterAsObject, { country: 'france' }>,
  'chapter'
>['chapter'];

type FrenchDivision = Pick<
  Extract<ChapterAsObject, { country: 'france' }>,
  'division'
>['division'];

const subject: {
  [key in FrenchSubject]: string;
} = {
  mathematics: 'Mathématiques',
  // mathematics_specialty: 'Mathématiques - spécialité',
};

type FrenchSubject = Pick<
  Extract<ChapterAsObject, { country: 'france' }>,
  'subject'
>['subject'];

export const commonMessages = {
  common: {
    logout: 'Se déconnecter',
    loading: 'Chargement...',
    title: 'Skloover',
    notAvailable: "Skloover n'est pas encore disponible sur les petits écrans",
    continue: 'Continuer',
    stop: 'Terminer',
    chapterAsObject: { chapter, subject, division, notion },
    chapter: 'Chapitre',
    division: 'Classe',
    country: 'Pays',
    subject: 'Matière',
    select: 'Sélectionner',
    reply: 'Répondre...',
    askQuestion: 'Poser une question...',
    drawTable: 'Tableau',
    send: 'Envoyer',
    problems: 'Problèmes',
    yes: 'oui',
    no: 'non',
    close: 'Fermer',
    lessons: 'Cours',
    writeFormula: 'Formule',
    createUser: 'Créer un utilisateur',
    gender: 'Sexe',
    male: 'Masculin',
    female: 'Féminin',
    products: 'Abonnement',
  },
};
