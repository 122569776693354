import { ReactElement } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppRoute } from '~/lib';
import {
  Error,
  Home,
  Lessons,
  Login,
  NotFound,
  Problems,
  Products,
  Session,
  Users,
  Welcome,
} from '~/pages';

import { AdminRequired } from './AdminRequired';
import { AuthenticationRequired } from './AuthenticationRequired';

export const AppRoutes = (): ReactElement => (
  <BrowserRouter>
    <Routes>
      <Route path={AppRoute.Home} element={<Home />} />
      <Route element={<AuthenticationRequired />}>
        <>
          <Route path={AppRoute.Welcome} element={<Welcome />} />
          <Route
            path={`${AppRoute.Session}/:sessionId`}
            element={<Session />}
          />
          <Route element={<AdminRequired />}>
            <Route path={AppRoute.Products} element={<Products />} />
            <Route path={AppRoute.Problems} element={<Problems />} />
            <Route path={AppRoute.Lessons} element={<Lessons />} />
            <Route path={AppRoute.Users} element={<Users />} />
          </Route>
        </>
      </Route>
      <Route path={AppRoute.NotFound} element={<NotFound />} />
      <Route path={AppRoute.Error} element={<Error />} />
      <Route path="*" element={<Navigate to={AppRoute.NotFound} />} />
      <Route path={AppRoute.Login} element={<Login />} />
    </Routes>
  </BrowserRouter>
);
