import { ReactElement, useContext } from 'react';

import { CreateProduct, Layout, Product } from '~/components';
import { trpc } from '~/lib';
import { SessionContext } from '~/navigation';

export const Products = (): ReactElement => {
  const { admin } = useContext(SessionContext);
  const { data } = trpc.productsList.useQuery();

  return (
    <Layout>
      <>
        {admin && <CreateProduct />}
        <div className="h-full flex items-center justify-around">
          <div className="flex flex-wrap items-center justify-center gap-2">
            {data?.products.map(product => (
              <Product
                key={product.id}
                name={product.name}
                allowedSubject={product.allowedSubject}
                price={product.price}
                priceId={product.priceId}
              />
            ))}
          </div>
        </div>
      </>
    </Layout>
  );
};
