import { LOGIN_PATH } from '@skloover/shared';

export enum AppRoute {
  Home = '/',
  Login = `/${LOGIN_PATH}`,
  NotFound = '/not-found',
  Error = '/error',
  Welcome = '/welcome',
  Session = '/session',
  Problems = '/problems',
  Problem = '/problem',
  Lessons = '/lessons',
  Users = '/users',
  Products = '/products',
}
