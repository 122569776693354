import { flattenMessages } from '~/lib/utils';

import { commonMessages } from './common';
import {
  errorMessages,
  joyrideMessages,
  loginMessages,
  notFoundMessages,
  problemsMessages,
  productMessages,
  sessionMessages,
  welcomeMessages,
} from './pages';

export const frenchMessages = flattenMessages({
  ...commonMessages,
  ...loginMessages,
  ...notFoundMessages,
  ...errorMessages,
  ...welcomeMessages,
  ...sessionMessages,
  ...problemsMessages,
  ...joyrideMessages,
  ...productMessages,
});
