import { useStripe } from '@stripe/react-stripe-js';
import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { SubjectAsObject } from '@skloover/shared';

import { AppRoute } from '~/lib';

import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui';

type ProductProps = {
  name: string;
  allowedSubject?: SubjectAsObject;
  price?: number;
  priceId?: string;
};

export const Product = ({
  name,
  price,
  priceId,
}: ProductProps): ReactElement => {
  const [stripeLoading, setStripeLoading] = useState(false);
  const stripe = useStripe();
  const item = { price: priceId, quantity: 1 };

  const redirectToCHeckout = async () => {
    setStripeLoading(true);
    // eslint-disable-next-line no-unsafe-optional-chaining
    await stripe?.redirectToCheckout({
      lineItems: [item],
      mode: 'subscription',
      successUrl: `${window.location.origin}${AppRoute.Products}`,
      cancelUrl: `${window.location.origin}${AppRoute.Error}`,
    });
    setStripeLoading(false);
  };

  return (
    <Card className="w-[350px]">
      <CardHeader>
        <CardTitle>
          <FormattedMessage id="product.oneSubject" />
        </CardTitle>
        <CardDescription>{name}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid w-full items-center gap-4">
          <div className="text-3xl">
            {price !== undefined ? (
              <FormattedMessage id="product.pricePerMonth" values={{ price }} />
            ) : (
              <FormattedMessage id="product.free" values={{ price }} />
            )}
          </div>
        </div>
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button onClick={redirectToCHeckout} disabled={stripeLoading}>
          <FormattedMessage id="product.subscribe" />
        </Button>
      </CardFooter>
    </Card>
  );
};
