/* eslint-disable max-lines */

/* eslint-disable complexity */
import { MathJax } from 'better-react-mathjax';
import { RotateCcw, Trash2 } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { Problem } from '@skloover/functions';
import { ChapterAsObject, getMaxNotionLevel } from '@skloover/shared';

import { Layout } from '~/components';
import { LoadingSpinner } from '~/components/icons/LoadingSpinner';
import {
  Badge,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/components/ui';
import { AppRoute, trpc } from '~/lib';

import { ProblemFilters, SELECTED_CHAPTER } from './ProblemFilters';

export const Problems = (): ReactElement => {
  const utils = trpc.useUtils();
  const selectedChapter = localStorage.getItem(SELECTED_CHAPTER) as
    | ChapterAsObject['chapter']
    | undefined;
  const [filters, setFilters] = useState<
    | {
        chapter: ChapterAsObject['chapter'] | undefined;
      }
    | undefined
  >(selectedChapter === undefined ? undefined : { chapter: selectedChapter });
  const { data: problems } = trpc.problemList.useQuery();
  const [filteredProblems, setFilteredProblems] = useState(problems);
  const { mutate: createProblems } = trpc.problemsCreate.useMutation({
    onSuccess: async () => {
      await utils.problemList.invalidate();
    },
  });
  const { mutate: deleteProblems } = trpc.problemsDelete.useMutation({
    onSuccess: async () => {
      await utils.problemList.invalidate();
    },
  });
  const {
    mutate: updateProblem,
    isPending: isUpdatePending,
    variables: updateVariables,
  } = trpc.problemUpdate.useMutation({
    onSuccess: async () => {
      await utils.problemList.invalidate();
    },
  });
  const {
    mutate: deleteProblem,
    isPending: isDeletePending,
    variables: deleteVariables,
  } = trpc.problemDelete.useMutation({
    onSuccess: async () => {
      await utils.problemList.invalidate();
    },
  });
  const navigate = useNavigate();
  const { mutate: createSession } = trpc.sessionCreate.useMutation({
    onSuccess: ({ id }) => navigate(`${AppRoute.Session}/${id}`),
  });

  useEffect(() => {
    setFilteredProblems(
      // @ts-ignore type instantiation
      problems?.filter(({ chapter }) =>
        filters?.chapter === undefined ? true : chapter === filters.chapter,
      ),
    );
  }, [problems, filters]);

  return (
    <Layout>
      <div className="space-y-2">
        <div className="flex items-center space-x-2">
          <Button size="sm" onClick={() => createProblems()}>
            <FormattedMessage id="problems.create" />
          </Button>
          <Button
            size="sm"
            onClick={() => deleteProblems()}
            variant="destructive"
          >
            <FormattedMessage id="problems.delete" />
          </Button>
        </div>
        <ProblemFilters setFilters={setFilters} filters={filters} />
        <Table className="h-full max-w-fit overflow-auto table-auto w-full">
          <TableHeader>
            <TableRow>
              <TableHead className="w-auto">
                <FormattedMessage id="problems.valid" />
              </TableHead>
              <TableHead className="text-center">
                <FormattedMessage id="common.chapter" />
              </TableHead>
              <TableHead>
                <FormattedMessage id="problems.level" />
              </TableHead>
              <TableHead className="text-center">
                <FormattedMessage id="problems.statement" />
              </TableHead>
              <TableHead className="text-right">
                <FormattedMessage id="problems.actions" />
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {/* @ts-ignore type infinite */}
            {(filteredProblems ?? []).map((problem: Problem) => {
              const text =
                typeof problem.problemStatement.statement['0']?.type ===
                  'string' &&
                problem.problemStatement.statement['0'].type === 'statement' &&
                problem.problemStatement.statement['0'].text[0].type === 'text'
                  ? problem.problemStatement.statement['0'].text[0].value
                  : typeof problem.problemStatement.statement['1']?.type ===
                        'string' &&
                      problem.problemStatement.statement['1'].type ===
                        'question'
                    ? problem.problemStatement.statement['1'].text[0].value
                    : '';

              const createProblemSession = () =>
                createSession({
                  chapterAsObject: {
                    country: problem.country,
                    program: problem.program,
                    division: problem.division,
                    subject: problem.subject,
                    chapter: problem.chapter,
                  },
                  problemId: problem.id,
                });

              const maxLevel = getMaxNotionLevel(
                problem.problemStatement.statement,
              );

              return (
                <TableRow key={problem.id} className="hover:cursor-pointer">
                  <TableCell className="w-auto" onClick={createProblemSession}>
                    <Badge variant={problem.tested ? 'secondary' : undefined}>
                      <FormattedMessage
                        id={`common.${problem.tested ? 'yes' : 'no'}`}
                      />
                    </Badge>
                  </TableCell>
                  <TableCell
                    className="text-center"
                    onClick={createProblemSession}
                  >
                    <FormattedMessage
                      id={`common.chapterAsObject.chapter.${problem.chapter}`}
                    />
                  </TableCell>
                  <TableCell>
                    <Badge>
                      <FormattedMessage id={`session.difficulty.${maxLevel}`} />
                    </Badge>
                  </TableCell>
                  <TableCell
                    className="overflow-hidden whitespace-nowrap"
                    onClick={createProblemSession}
                  >
                    <MathJax dynamic>{text}</MathJax>
                  </TableCell>
                  <TableCell className="gap-2">
                    <div className="flex items-center gap-2">
                      <Button
                        size="sm"
                        onClick={() => updateProblem({ problemId: problem.id })}
                      >
                        {isUpdatePending &&
                        updateVariables.problemId === problem.id ? (
                          <LoadingSpinner className="animate-spin" />
                        ) : (
                          <RotateCcw size={16} />
                        )}
                      </Button>
                      <Button
                        size="sm"
                        variant="destructive"
                        onClick={() => deleteProblem({ problemId: problem.id })}
                      >
                        {isDeletePending &&
                        deleteVariables.problemId === problem.id ? (
                          <LoadingSpinner className="animate-spin" />
                        ) : (
                          <Trash2 size={16} />
                        )}
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Layout>
  );
};
