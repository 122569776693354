import { fetchAuthSession } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { Amplify } from 'aws-amplify';
import { MathJaxContext } from 'better-react-mathjax';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { ReactElement, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { Toaster } from './components/ui';
import { frenchMessages, isProd, trpc } from './lib';
import { AppRoutes } from './navigation';

const postHogOptions: Partial<PostHogConfig> = {
  api_host: `https://${process.env.VITE_REVERSE_PROXY_DOMAIN_NAME}`,
  ui_host: process.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  disable_session_recording: !isProd(),
};
const stripePromise = loadStripe(process.env.VITE_STRIPE_PUBLIC_KEY ?? '');

export const App = (): ReactElement => {
  const [queryClient] = useState(() => new QueryClient());

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
        userPoolClientId: import.meta.env.VITE_APP_USER_POOL_CLIENT_ID,
        loginWith: { email: true },
        passwordFormat: { minLength: 8 },
      },
    },
    API: {
      REST: {
        api: {
          endpoint: import.meta.env.VITE_APP_API_URL,
          region: import.meta.env.VITE_APP_REGION,
        },
      },
    },
  });

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          url: `${import.meta.env.VITE_APP_API_URL}`,
          headers: async () => {
            const { tokens } = await fetchAuthSession();

            if (tokens?.idToken === undefined) {
              return {};
            }

            return {
              Authorization: `Bearer ${tokens.idToken.toString()}`,
            };
          },
        }),
      ],
    }),
  );

  const mathJaxConfig = {
    loader: { load: ['[tex]/html', '[tex]/color'] },
    tex: {
      packages: { '[+]': ['html', 'color'] },
      inlineMath: [
        ['$', '$'],
        ['\\(', '\\)'],
      ],
      displayMath: [
        ['$$', '$$'],
        ['\\[', '\\]'],
      ],
    },
  };

  Sentry.init({
    dsn: process.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app\.skloover\.fr/],
    enabled: process.env.NODE_ENV !== 'development',
  });

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <PostHogProvider
        apiKey={process.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
        options={postHogOptions}
      >
        <Elements stripe={stripePromise}>
          <QueryClientProvider client={queryClient}>
            <IntlProvider
              messages={frenchMessages}
              locale="fr"
              defaultLocale="fr"
            >
              <MathJaxContext
                renderMode="post"
                hideUntilTypeset="first"
                config={mathJaxConfig}
                version={3}
                src="/es5/tex-mml-chtml.js"
              >
                <AppRoutes />
                <Toaster />
              </MathJaxContext>
            </IntlProvider>
          </QueryClientProvider>
        </Elements>
      </PostHogProvider>
    </trpc.Provider>
  );
};
