/* eslint-disable max-lines */
import { zodResolver } from '@hookform/resolvers/zod';
import { Plus } from 'lucide-react';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { z } from 'zod';

import { CHAPTERS, Country, Division } from '@skloover/shared';

import { LoadingSpinner } from '~/components/icons/LoadingSpinner';
import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~/components/ui';
import { trpc, useIntl } from '~/lib';

const formSchema = z
  .object({
    name: z.string().min(1),
    country: z.string().min(1),
    division: z.string().min(1),
    subject: z.string().min(1),
    free: z.boolean().default(false),
  })
  .strict();

export const CreateProduct = (): ReactElement => {
  const utils = trpc.useUtils();
  const { mutate, isPending } = trpc.productCreate.useMutation({
    onSuccess: async () => {
      await utils.productsList.invalidate();
      form.reset();
    },
  });
  const t = useIntl();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      country: '',
      division: '',
      subject: '',
      free: false,
    },
  });

  const submit = ({
    name,
    country,
    division,
    subject,
    free,
  }: z.infer<typeof formSchema>) => {
    mutate({
      name,
      allowedSubject: { country, program: '2024', division, subject },
      free,
    });
  };

  const selectedDivision = form.watch('division') as Division | '';
  const selectedCountry = form.watch('country') as Country | '';

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <div className="flex items-center gap-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="flex-grow">
                <FormControl>
                  <Input
                    placeholder={t.formatMessage({ id: 'product.name' })}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="country"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      id="country"
                      className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                    >
                      <SelectValue
                        placeholder={t.formatMessage({
                          id: 'common.country',
                        })}
                      />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent
                    position="popper"
                    className="max-w-[var(--radix-select-trigger-width)]"
                  >
                    {Object.keys(CHAPTERS).map(country => (
                      <SelectItem
                        value={country}
                        key="country"
                        className="max-w-100"
                      >
                        {country}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="division"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  disabled={selectedCountry === ''}
                >
                  <FormControl>
                    <SelectTrigger
                      id="division"
                      className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                    >
                      <SelectValue
                        placeholder={t.formatMessage({
                          id: 'common.division',
                        })}
                      />
                    </SelectTrigger>
                  </FormControl>
                  {selectedCountry !== '' && (
                    <SelectContent
                      position="popper"
                      className="max-w-[var(--radix-select-trigger-width)]"
                    >
                      {Object.keys(CHAPTERS[selectedCountry]['2024']).map(
                        division => (
                          <SelectItem
                            value={division}
                            key="division"
                            className="max-w-100"
                          >
                            {division}
                          </SelectItem>
                        ),
                      )}
                    </SelectContent>
                  )}
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subject"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  disabled={selectedCountry === '' || selectedDivision === ''}
                >
                  <FormControl>
                    <SelectTrigger
                      id="subject"
                      className="whitespace-normal [&>span]:text-left [&>svg]:shrink-0"
                    >
                      <SelectValue
                        placeholder={t.formatMessage({
                          id: 'common.subject',
                        })}
                      />
                    </SelectTrigger>
                  </FormControl>
                  {selectedDivision !== '' && selectedCountry !== '' && (
                    <SelectContent
                      position="popper"
                      className="max-w-[var(--radix-select-trigger-width)]"
                    >
                      {Object.keys(
                        CHAPTERS[selectedCountry]['2024'][selectedDivision],
                      ).map(subject => (
                        <SelectItem
                          value={subject}
                          key="subject"
                          className="max-w-100"
                        >
                          {subject}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  )}
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="free"
            render={({ field }) => (
              <FormItem className="flex items-center space-y-0 space-x-2">
                <FormLabel htmlFor="free">
                  <FormattedMessage id="product.free" />
                </FormLabel>
                <Checkbox
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormItem>
            )}
          />
          <Button type="submit">
            {isPending ? (
              <LoadingSpinner className="h-4 w-4 animate-spin" />
            ) : (
              <Plus className="h-4 w-4" />
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};
